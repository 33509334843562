import { Breakpoints } from './breakpoint.interfaces'
import { between, down, keys, only, up, values, width } from './utils'

export const breakpoints: Breakpoints = {
    between,
    down,
    keys,
    only,
    up,
    values,
    width,
}